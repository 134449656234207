// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// hooks
import useAuth from './hooks/useAuth';

// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen from './components/LoadingScreen';
import GoogleAnalytics from './components/GoogleAnalytics';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import ThemeLocalization from './components/ThemeLocalization';
import DataContextProvider from './contexts/DataContext';
import ClientContextProvider from './contexts/ClientContext';

// ----------------------------------------------------------------------

export default function App() {
  const { isInitialized } = useAuth();

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <DataContextProvider>
          <ClientContextProvider>
            <ThemeLocalization>
              <RtlLayout>
                <NotistackProvider>
                  <Settings />
                  <ScrollToTop />
                  <GoogleAnalytics />
                  {isInitialized ? <Router /> : <LoadingScreen />}
                </NotistackProvider>
              </RtlLayout>
            </ThemeLocalization>
          </ClientContextProvider>
        </DataContextProvider>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
 