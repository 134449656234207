// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  changepassword: path(ROOTS_AUTH, '/change-password')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  page404: '/404',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    single_source: path(ROOTS_DASHBOARD, '/single_source'),
    hireConsultant: path(ROOTS_DASHBOARD, '/hire-consultant'),
    team: path(ROOTS_DASHBOARD, '/team')
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account')
  },
  consultants: {
    root: path(ROOTS_DASHBOARD, '/consultants'),
    onboarding: path(ROOTS_DASHBOARD, '/consultants/onboarding'),
    interview: path(ROOTS_DASHBOARD, '/consultants/interview'),
    onboardingDetails: path(ROOTS_DASHBOARD, '/consultants/onboarding/:id'),
    interviewDetails: path(ROOTS_DASHBOARD, '/consultants/interview/:id'),
    consultant_lists: path(ROOTS_DASHBOARD, '/consultants/consultant_lists'),
    consultant_info: path(ROOTS_DASHBOARD, '/consultants/consultant_info/:id'),
  },
  support: {
    root: path(ROOTS_DASHBOARD, '/support'),
    support: path(ROOTS_DASHBOARD, '/support'),
    support: path(ROOTS_DASHBOARD, `/support/:id`),
  },
  requests: {
    root: path(ROOTS_DASHBOARD, '/requests'),
    requests: path(ROOTS_DASHBOARD, '/requests'),
    requests: path(ROOTS_DASHBOARD, `/requests/:id`),
  },
  single_source: {
    root: path(ROOTS_DASHBOARD, '/single_source'),
    newSingleSource: path(ROOTS_DASHBOARD, '/single_source/new'),
    singleSourceDetails: path(ROOTS_DASHBOARD, '/single_source/details/:id'),
  },
  hireConsultant: {
    root: path(ROOTS_DASHBOARD, '/hire-consultant'),
    newHireConsultant: path(ROOTS_DASHBOARD, '/hire-consultant/new'),
    hireConsultantDetails: path(ROOTS_DASHBOARD, '/hire-consultant/details'),
  },
  escrow: {
    root: path(ROOTS_DASHBOARD, '/escrow')
  },
  payments: {
    root: path(ROOTS_DASHBOARD, '/payments'),
  },
  hireTeam: {
    root: path(ROOTS_DASHBOARD, '/team'),
    newHireTeam: path(ROOTS_DASHBOARD, '/hire-team/new'),
    hireTeamDetails: path(ROOTS_DASHBOARD, '/team/details/:id'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all')
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),



};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
