// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Label from '../../components/Label';
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking')
};

const sidebarConfig = [
  // ----------------------------------------------------------------------
  {
    subheader: 'admin',
    items: [
      {
        title: 'Dashboard',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard
      },
      { title: 'Single Gig', path: PATH_DASHBOARD.general.single_source, icon: ICONS.ecommerce },
      { title: 'Managed Services', path: PATH_DASHBOARD.general.team, icon: ICONS.analytics },
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // MANAGEMENT : USER
      {
        title: 'user',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: 'account', path: PATH_DASHBOARD.user.account }
        ]
      },
      {
        title: 'consultant',
        path: PATH_DASHBOARD.consultants.root,
        icon: ICONS.booking,
        children: [
          { title: 'list', path: PATH_DASHBOARD.consultants.consultant_lists },
          { title: 'onboarding', path: PATH_DASHBOARD.consultants.onboarding },
          { title: 'interview', path: PATH_DASHBOARD.consultants.interview }
        ]
      },
      { title: 'Payments', path: PATH_DASHBOARD.payments.root },
      {
        title: 'Escrow', path: PATH_DASHBOARD.escrow.root
      },
      { title: 'Support', path: PATH_DASHBOARD.support.root },
      { title: 'Requests', path: PATH_DASHBOARD.requests.root }
    ]
  },


  // // APP
  // // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: <Label color="error">2</Label>
  //     },
  //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  //   ]
  // }
];

export default sidebarConfig;
